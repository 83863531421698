import { Box, makeStyles, Paper, Typography, Hidden } from '@material-ui/core';
import React from 'react';
import FluidImage from '@components/fluid-image';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    minHeight: 500,
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  content: {
    flexBasis: '60%',
    padding: theme.spacing(9),
    textAlign: 'center',
    display: 'flex',
    '&>div': { margin: 'auto' },
    '& ul': {
      margin: 'auto',
      textAlign: 'left',
      paddingTop: theme.spacing(2),
      width: 'fit-content',
    },
    [theme.breakpoints.down('sm')]: {
      background: 'black',
      color: '#fff',
      bottom: 0,
      flex: '0 0 100%',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      opacity: 0.6,
    },
  },
  imageContainer: {
    padding: theme.spacing(9),
    flexBasis: '40%',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      flex: '0 0 100%',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  image: {
    height: '100%',
  },
}));

const Introduction = props => {
  const classes = useStyles();
  return (
    <section>
      <Paper className={classes.root} square={true} elevation={0}>
        <Box className={classes.imageContainer}>
          <FluidImage
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Lauren Wall"
            className={classes.image}
          />
        </Box>
        <Box className={classes.content}>
          <Box>
            <Typography variant="h2" component="h1">
              “Set your aims and let’s achieve them”
            </Typography>
            <Hidden xsDown>
              <Typography variant="body1" gutterBottom>
                My name is Lauren, I am a level 3 Personal Trainer, Online Coach
                and setting goals has changed my life.
              </Typography>
              <Typography variant="body1" gutterBottom>
                I was a stressed Secondary School teacher, living for the
                weekend and neglecting myself in the process. When I first
                walked into a gym setting, I was terrified! However, it was
                within the gym that I found myself again, started setting goals,
                began to look after myself and found my passion for helping
                others all over again. I studied dance from a young age until my
                Post Graduate Certificate in Education. However, it was the
                wanting to help others to succeed that pushed me into that
                profesion. My aim as a coach is to assist you with setting you
                own goals, work on them and most importantly,{' '}
                <strong>achieve</strong> them!
              </Typography>
            </Hidden>
          </Box>
        </Box>
      </Paper>
    </section>
  );
};

export default Introduction;
