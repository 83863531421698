import Introduction from '@features/home/Introduction/introduction';
import PersonalTrainingPane from '@features/home/Personal-Training/PersonalTrainingPane';
import ServicesPane from '@features/home/Services-Pane/ServicesPane';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/layout';
import SEO from '../components/seo';

export const INSTA_URL = `https://www.instagram.com/laurencarlaa/?__a=1`;

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#222222',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& h2': {
      color: theme.palette.text.secondary,
    },
  },
}));

const CheckoutModal = ({ state: { title, message }, open, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{ className: classes.paper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="redirect-from-checkout-message"
      aria-describedby="return from redirect message"
    >
      <DialogTitle id="edirect-from-checkout-message">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const mapState = state => ({ darkMode: state.view.darkMode });

const ConnectedModal = connect(mapState, null)(CheckoutModal);

const IndexPage = ({ location }) => {
  const [state, setState] = useState({ title: '', message: '', show: true });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      setState(prevState => ({
        ...prevState,
        title: 'Welcome to the Team!',
        message: 'I will be in touch via email to discuss your new plan.',
      }));
    }
    if (query.get('cancelled')) {
      setState(prevState => ({
        ...prevState,
        title: 'Is there something wrong?',
        message: `If you have any questions feel free to email me and I will get back to you as soon as possible.`,
      }));
    }
    return () => {};
  }, []);

  const handleClose = () => {
    navigate('/');
    window.history.pushState({}, 'Lauren Carla Coaching', '/');
    setState({ title: '', message: '', show: false });
  };

  return (
    <Layout>
      <SEO title="Personal Trainer & Online Coach" />
      <Introduction />
      <ServicesPane location={location} />
      <PersonalTrainingPane />
      <ConnectedModal
        open={state.message && state.show ? true : false}
        handleClose={handleClose}
        state={state}
      />
    </Layout>
  );
};

// export default IndexPage;
export default IndexPage;
