import { Button } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
  }
  return stripePromise;
};

class BuyNowButton extends Component {
  redirectToCheckout = async event => {
    event.preventDefault();
    const { product, mode, home } = this.props;
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: product, quantity: 1 }],
      mode: mode,
      successUrl: `${home}?success=true`,
      cancelUrl: `${home}?cancelled=true`,
    });
    if (error) {
      console.warn('Error:', error);
    }
  };

  render() {
    const { text, color } = this.props;
    return (
      <Button color={color} onClick={this.redirectToCheckout}>
        {text}
      </Button>
    );
  }
}

BuyNowButton.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

BuyNowButton.defaultProps = {
  color: 'secondary',
};

export default BuyNowButton;
