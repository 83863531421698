import { Box, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  flexWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    '&>div:nth-child(n)': {
      textAlign: 'center',
      padding: theme.spacing(4),
      flexBasis: '100%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
  },
  mapContainer: {
    // position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: '20em',
    },
    '&>div': {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    // height: '100%',
    // minHeight: '150px',
  },
}));

const PersonalTrainingPane = props => {
  const classes = useStyles();
  return (
    <section id="personal-training">
      <Paper className={classes.root} square={true} elevation={0}>
        <Typography className={classes.title} variant="h2" component="h1">
          1-2-1 Personal training
        </Typography>
        <Box className={classes.flexWrapper}>
          <Box>
            <Typography variant="body1">
              I am available for one to one session out of a private gym based
              in Gateshead.
            </Typography>
            <Typography variant="body1">
              If you feel that you need that personal touch to help you reach
              your goals please contact Spark{' '}
              <Link
                color="secondary"
                href="https://www.sparkhealth.co.uk"
                target="_blank"
                rel="noopener"
              >
                here
              </Link>
              .
            </Typography>
          </Box>
        </Box>
      </Paper>
    </section>
  );
};

export default PersonalTrainingPane;
