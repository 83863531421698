import BuyNowButton from '@features/online-payments/BuyNowButton';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import { StaticQuery } from 'gatsby';
import React from 'react';

const one_time_desc = [
  'Adaptable training program',
  'Nutritional guidelines to reach your goal',
  'Meal ideas to fit your plan',
  'No obligation',
];
const recurring_desc = [
  'Ongoin support',
  'Weekly check-ins',
  'Video and WhatsApp comunication',
  'Updates to plan as needed',
];

const useStyles = makeStyles(theme => {
  const palette = theme.palette;
  const darkTheme = theme.palette.type === 'dark';
  return {
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    flexWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        '& div:nth-child(2)': {
          transform: 'scale(1.1)',
        },
      },
    },
    container: {
      padding: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        minWidth: 350,
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
        flexBasis: '100%',
      },
    },
    product: {
      background: darkTheme
        ? palette.background.default
        : palette.primary.light,
      color: darkTheme ? palette.common.black : palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '100%',
      textAlign: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      '& ul': {
        margin: '16px auto',
        textAlign: 'left',
        paddingRight: theme.spacing(3),
        width: 'fit-content',
      },
    },
  };
});

const paymentModes = {
  one_time: 'payment',
  recurring: 'subscription',
};

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2);
  let numberFormat = new Intl.NumberFormat(['en-GB'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  return numberFormat.format(price);
};

const Product = ({ price, location }) => {
  const classes = useStyles();
  const { id, product, unit_amount, currency, type } = price;
  return (
    <Box className={classes.container}>
      <Paper className={classes.product} elevation={5}>
        <Typography color="secondary" variant="h6" component="h1">
          {product.name}
        </Typography>
        <Typography variant="h2">
          {formatPrice(unit_amount, currency)}
        </Typography>
        <Typography variant="caption" display="block">
          {
            {
              recurring: 'per month',
              one_time: 'per plan',
            }[type]
          }
        </Typography>
        <ul>
          {'one_time' === type &&
            one_time_desc.map(bullet => <li key={bullet}>{bullet}</li>)}
          {'recurring' === type &&
            recurring_desc.map(bullet => <li key={bullet}>{bullet}</li>)}
        </ul>
        <BuyNowButton
          text="buy now"
          product={id}
          mode={paymentModes[type]}
          home={location.origin}
        />
      </Paper>
    </Box>
  );
};

const ServicesPane = props => {
  const classes = useStyles();
  return (
    <section id="services">
      <Paper className={classes.root} square={true} elevation={0}>
        <Typography className={classes.title} variant="h2" component="h1">
          A PLAN JUST RIGHT FOR YOU
        </Typography>
        <Box className={classes.flexWrapper}>
          <StaticQuery
            query={graphql`
              query ProductPrices {
                allStripePrice(
                  filter: {
                    active: { eq: true }
                    product: { active: { eq: true } }
                  }
                  sort: { fields: unit_amount, order: ASC }
                ) {
                  edges {
                    node {
                      id
                      currency
                      product {
                        id
                        name
                      }
                      unit_amount
                      type
                    }
                  }
                }
              }
            `}
            render={({ allStripePrice }) => (
              <>
                {allStripePrice.edges.map(({ node: price }) => (
                  <Product key={price.id} price={price} {...props} />
                ))}
              </>
            )}
          />
        </Box>
      </Paper>
    </section>
  );
};

export default ServicesPane;
